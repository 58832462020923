import { Grid } from "@mui/material"
import React from "react"
import SmallCard from "../../shared/Card/SmallCard"
import "./businessNeeds.scss"

const businessNeedsList = [
  {
    id: 1,
    heading: "Automated solution for DSPs",
    description: "",
    icon: "/driverbee/businessNeeds/1.svg",
  },
  {
    id: 2,
    heading: "Increased overall business performance",
    description: "",
    icon: "/driverbee/businessNeeds/2.svg",
  },
  {
    id: 3,
    heading: "Improved operational efficiency and effectiveness",
    description: "",
    icon: "/driverbee/businessNeeds/3.svg",
  },
  {
    id: 4,
    heading: "Enhanced decision making with access to real-time",
    description: "",
    icon: "/driverbee/businessNeeds/4.svg",
  },
  {
    id: 5,
    heading: "Better resource allocation and utilization",
    description: "",
    icon: "/driverbee/businessNeeds/5.svg",
  },
  {
    id: 6,
    heading: "Increased productivity and profitability",
    description: "",
    icon: "/driverbee/businessNeeds/6.svg",
  },
  {
    id: 7,
    heading: "Streamlined coaching and development of drivers",
    description: "",
    icon: "/driverbee/businessNeeds/7.svg",
  },
]

export default function BusinessNeeds() {
  return (
    <div className="driverbee-business-needs-section">
      <div className="container">
        <h2> Business needs it fulfills </h2>
        <Grid
          container
          columnSpacing={{ md: 2, sm: 2 }}
          rowSpacing={{ md: 2, sm: 2 }}
        >
          {businessNeedsList.map(soltuion => (
            <Grid item sm={12} md={4} className="business-need-card">
              <SmallCard {...soltuion} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}
