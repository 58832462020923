import React from "react"
import styled from "styled-components"

export default function SmallCard({ icon, heading, description }) {
  return (
    <StyledSmallCard description={Boolean(description.trim())}>
      <div className="icon-wrapper">
        <img src={icon} alt={heading} loading="lazy" />
      </div>
      <h3> {heading} </h3>
      <p> {description} </p>
    </StyledSmallCard>
  )
}

const StyledSmallCard = styled.div`
  background: rgba(208, 212, 223, 0.3);
  border-radius: 16px;
  padding: 24px;
  height: 100%;

  .icon-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 13px;
  }
  h3 {
    font-family: "PoppinSemi";
    font-size: 1.25rem;
    line-height: 136.5%;
    text-transform: capitalize;
    color: #212121;
    margin-bottom: 13px;
  }
  p {
    font-family: "Poppins";
    font-size: 1rem;
    line-height: 1.668rem;
    color: #212121;
  }

  @media only screen and (min-width: 240px) and (max-width: 899px) {
    h2 {
      text-align: center;
    }
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }
`
