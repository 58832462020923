import React, { useEffect } from "react"
import Layout from "../../components/Layout"

import Driverbee from "../../components/Driverbee"
export default function TrackHero() {
  return (
    <>
      <Layout>
        <Driverbee />
      </Layout>
    </>
  )
}
