import styled from "styled-components"

export const StyledHero = styled.div`
  padding: 6rem 0 3rem;
  .text-side {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .driverbee-logo {
      width: 100%;
    }
    h1 {
      font-family: "PoppinsBold";
      font-weight: 700;
      font-size: 3.625rem;
      line-height: 136.5%;
      text-transform: capitalize;
      color: #212121;
    }

    .tech-tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      .driverbee-tag {
        font-family: "Poppins";
        font-size: 1rem;
        line-height: 1.5rem;
        color: #ffffff;
        background-color: #01244c;
        padding: 10px 23px;
        border-radius: 100px;
        white-space: pre;
      }
    }
  }

  /* Image side */
  .hero-img-side {
    .image-wrapper {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  /* ABOUT SECTIONS */

  .about-driverbee {
    padding: 22px 0;
    border-top: 2px solid #5c6375;
    border-bottom: 2px solid #5c6375;
    display: flex;
    align-items: center;
    .about-section-driverbee {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 24px;
      height: 105px;
      overflow: hidden;

      /* margin: auto; */
      h4 {
        font-family: "PoppinSemi";
        font-size: 1.5rem;
        line-height: 2rem;
        color: #01244c;
      }
      p {
        font-family: "Poppins";
        font-size: 1rem;
        line-height: 1.5rem;
        color: #212121;
      }
    }
    .left-border {
      border-left: 2px solid #5c6375;
    }
  }

  @media only screen and (min-width: 240px) and (max-width: 899px) {
    .text-side {
      padding-top: 3rem;
      h1 {
        font-size: 3rem;
        margin: 2rem 0;
      }
      .tech-tags {
        width: 100%;
        white-space: pre;
        .driverbee-tag {
          font-size: 1rem;
        }
      }
    }
    .hero-img-side {
      .image-wrapper {
        img {
          margin-top: 4rem;
        }
      }
    }

    /* about sections */
    .about-driverbee {
      .about-section-driverbee {
        width: 100%;
        padding: 10px 0;
        height: auto;
      }
      .left-border {
        border: none;
      }
    }
  }
`
