import React, { useState } from "react"
import styled from "styled-components"
import "./features.scss"

const featuresList = [
  {
    id: 1,
    feature: "Approve/Dispute",
    icon: "/driverbee/features/icons/1.svg",
    icon2: "/driverbee/features/icons/11.svg",
    image: "/driverbee/features/0.png",
    paragraph:
      "A feature accessible to drivers for approval or dispute of results.",
  },
  {
    id: 2,
    feature: "Edit/Reject",
    icon: "/driverbee/features/icons/2.svg",
    icon2: "/driverbee/features/icons/12.svg",
    image: "/driverbee/features/2.png",
    paragraph:
      "A feature accessible to managers/administrators for editing or rejecting results.",
  },
  {
    id: 3,
    feature: "File Parsing",
    icon: "/driverbee/features/icons/3.svg",
    icon2: "/driverbee/features/icons/13.svg",
    image: "/driverbee/features/3.png",
    paragraph:
      "A feature that parses data and generates analytics reports, which can be downloaded in PDF format.",
  },
  {
    id: 4,
    feature: "Smart Dashboard",
    icon: "/driverbee/features/icons/4.svg",
    icon2: "/driverbee/features/icons/14.svg",
    image: "/driverbee/features/4.png",
    paragraph:
      "An in-app dashboard that automatically analyzes driver behavioral data and generates personalized reports for improved coaching.",
  },
  {
    id: 5,
    feature: "Trait Assessment",
    icon: "/driverbee/features/icons/5.svg",
    icon2: "/driverbee/features/icons/15.svg",
    image: "/driverbee/features/5.png",
    paragraph:
      "A feature that allows both managers and drivers to evaluate and improve performance by providing an analytical report at the end of each week.",
  },
]

export default function Features() {
  const [active, setActive] = useState({
    id: 1,
    feature: "Approve/Dispute",
    icon: "/driverbee/features/icon/1.svg",
    icon2: "/driverbee/features/icon/11.svg",
    image: "/driverbee/features/0.png",
    paragraph:
      "A feature accessible to drivers for approval or dispute of results.",
  })

  const clickHandler = feature => {
    setActive(feature)
  }

  return (
    <div className="driverbee-features-main overflow-hidden">
      <div className="container">
        <div className="features-body">
          <div
            className="features-box"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-delay="500"
          >
            <div className="features-list">
              <div className="headers">
                <h2> Features </h2>
              </div>
              {featuresList.map(feature => (
                <SingleFeature
                  key={`feature-${feature.feature}`}
                  active={active.id === feature.id}
                  onClick={() => clickHandler(feature)}
                >
                  <div
                    className={`button-wrapper ${
                      active.id === feature.id ? "active" : ""
                    }`}
                  >
                    <div className="icon-wrapper">
                      <img
                        src={
                          active.id === feature.id
                            ? feature.icon2
                            : feature.icon
                        }
                        alt={feature.feature}
                        loading="lazy"
                      />
                    </div>
                    <h6> {feature.feature} </h6>
                  </div>
                </SingleFeature>
              ))}
            </div>
            <div
              className="feature-image-side"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="250"
            >
              <div className="image-wrapper">
                <img src={active.image} alt="feature-1" loading="lazy" />
              </div>
              <h5>{active.feature}</h5>
              <p>{active.paragraph}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SingleFeature = styled.div`
  .button-wrapper {
    display: grid;
    grid-template-columns: 64px 1fr;
    align-items: center;
    padding: 12px;
    gap: 12px;
    border-radius: 16px;
    background: rgba(208, 212, 223, 0.3);
    min-width: 290px;
    min-height: 88px;
    margin-bottom: 22px;
    .icon-wrapper {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      padding: 8px;
      img {
        width: 100%;
      }
    }
    &.active {
      background-color: #183266;
      h6 {
        color: white !important;
      }
    }
    h6 {
      font-family: "PoppinSemi";
      font-size: 1.125rem;
      line-height: 136.5%;
      text-transform: capitalize;
      color: #0d111c;
      margin-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 240px) and (max-width: 424px) {
    .button-wrapper {
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 899px) {
    .button-wrapper {
      grid-template-columns: 45px;
      margin-bottom: 0px;
      min-width: 45px;
      min-height: 45px;
      .icon-wrapper {
        height: 100%;
        width: 100%;
      }
      h6 {
        display: none;
      }
    }
  }
`
