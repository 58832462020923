import React from "react"
import styled from "styled-components"

export default function CardThree({ icon, heading, description }) {
  return (
    <StyledCard>
      <div className="img-wrapper">
        <img src={icon} alt={heading} loading="lazy" />
      </div>
      <h4> {heading} </h4>
      <p> {description} </p>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(208, 212, 223, 0.3);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .img-wrapper {
    width: 70px;
    height: 70px;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h4 {
    font-family: "PoppinSemi";
    font-size: 1.5rem;
    line-height: 115.5%;
    color: #0d111c;
  }
  p {
    font-family: "Poppins";
    font-size: 0.9rem;
    line-height: 1.5rem;
    color: #0d111c;
  }

  @media only screen and (min-width: 240px) and (max-width: 899px) {
    margin: auto;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`
