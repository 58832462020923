import React from "react"
import AboutProject from "./AboutProject"
import AppDisplayImage from "./AppDisplayImage"
import Hero from "./Hero"
import KeyChallengaes from "./KeyChallanges"
import Results from "./Results"
import SolutionSection from "./SolutionSection"
// import TalkToUsDark from "../shared/TalkToUsBlue/index"
import BusinessNeeds from "./BusinessNeeds"
import TechStack from "./TechStack"
import ReviewSection from "./ReviewSection"
import FinalProduct from "./FinalProduct"
import Features from "./Features"
import ContactUs from "./Features/ContactUs"

export default function DriverbeePage() {
  return (
    <div>
      <Hero />
      <AboutProject />
      <AppDisplayImage url="/driverbee/key-ch-img.png" />
      <KeyChallengaes />
      <AppDisplayImage url="/driverbee/app-display-img.png" />
      <SolutionSection />
      <AppDisplayImage url="/driverbee/app-screens.png" />
      <Features />
      <Results />
      {/* <TalkToUsDark
        header="Need help improving your app, just as DriverBee did?"
        text="Let’s talk. We’ve got some experience."
        btnText="Talk to US"
      /> */}
      <TechStack />
      <BusinessNeeds />
      {/* <FinalProduct /> */}
      <ReviewSection />
      <ContactUs className="no-padding" />
    </div>
  )
}
