import React from "react"
import Heading from "../../shared/Heading"
import ContactForm from "../../shared/ServicesFooter/ContactForm"
import "../../../components/ContactUs/Footer.scss"
import "../../shared/Hero/Hero.scss"
import { Grid } from "@mui/material"

export default function ContactUs({ className = "" }) {
  return (
    <div
      className={`contact-us-main ${className}`}
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-anchor-placement="top-center"
    >
      <div className="container position-relative">
        <div className="row">
          <div className="col-12">
            <Heading variant="40" className="pb-2 text-capitalize">
              Ready to get started?
            </Heading>
            <div className="text">
              Submit the form and our team will be right with you!
            </div>
          </div>
          <Grid container className="col-12">
            <Grid item md={12}>
              <ContactForm />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
