import { Grid } from "@mui/material"
import React from "react"
import SmallCard from "../../shared/Card/SmallCard"
import "./solutionSection.scss"

const solutions = [
  {
    id: 1,
    heading: "Performance Monitoring",
    description:
      "Allows managers to actively monitor driver’s performance and intervene when needed",
    icon: "/driverbee/solutions/1.svg",
  },
  {
    id: 2,
    heading: "Coaching System",
    description:
      "In app coaching system allows managers to coach drivers or just provide performance tips",
    icon: "/driverbee/solutions/2.svg",
  },
  {
    id: 3,
    heading: "Trait Assessment",
    description:
      "Personality trait assessment for the drivers tied to their driving performance",
    icon: "/driverbee/solutions/3.svg",
  },
  {
    id: 4,
    heading: "Performance Insights",
    description:
      "Integrated dashboard provides drivers with insights into their performance",
    icon: "/driverbee/solutions/4.svg",
  },
  {
    id: 5,
    heading: "Behavioral Reports",
    description:
      "Advanced analytics generates accurate, personalized reports on driver behavior",
    icon: "/driverbee/solutions/5.svg",
  },
  {
    id: 6,
    heading: "Growth Patterns",
    description:
      "Easy for the managers to understand and support driver’s professional growth",
    icon: "/driverbee/solutions/6.svg",
  },
  {
    id: 7,
    heading: "Rewards System",
    description:
      "Helps drivers maximize incentives and achieve higher performance levels",
    icon: "/driverbee/solutions/7.svg",
  },
]

export default function SolutionSection() {
  return (
    <div
      className="driverbee-solution-section"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="250"
    >
      <div className="container">
        <h2> Our Solution </h2>
        <Grid
          container
          columnSpacing={{ md: 2, sm: 2 }}
          rowSpacing={{ md: 2, sm: 2 }}
          className="driverbee-solution-section-items"
        >
          {solutions.map(soltuion => (
            <Grid item sm={12} md={4} className="w-100">
              <SmallCard {...soltuion} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}
