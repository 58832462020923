import React from "react"
import "./reivewSection.scss"

const Icon = () => {
  return (
    <svg
      width="111"
      height="91"
      viewBox="0 0 111 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1905 44.1748L40.9643 0H21.1429L0 49.034V91H46.6905V44.1748H28.1905ZM92.5 44.1748L105.274 0H85.4524L64.3095 49.034V91H111V44.1748H92.5Z"
        fill="#0D111C"
      />
    </svg>
  )
}

export default function ReviewSection() {
  return (
    <div className="driverbee-review-section">
      <div className="container">
        <div className="review-area">
          <Icon />
          <p>
            {" "}
            "They use their experience to ensure that we meet our project
            objectives."
          </p>
          <div className="signature">
            <h5> Jeff Brooks </h5>
            <h6> Founder & CEO </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
