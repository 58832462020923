import { Grid } from "@mui/material"
import React from "react"
import "./aboutProject.scss"

export default function AboutProject() {
  return (
    <div
      className="about-project-container"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="250"
    >
      <div className="container">
        <Grid container>
          <Grid item sm={12} md={5} className="about-text-side">
            <div className="text-wrapper">
              <h1>About The Project </h1>
            </div>
          </Grid>
          <Grid item sm={12} md={7} className="about-side">
            <div className="icons-row">
              <div className="icon-wrapper">
                <img
                  src="/driverbee/about/1.svg"
                  alt="about-icon-1"
                  loading="lazy"
                />
              </div>
              <div className="icon-wrapper">
                <img
                  src="/driverbee/about/2.svg"
                  alt="about-icon-2"
                  loading="lazy"
                />
              </div>
              <div className="icon-wrapper">
                <img
                  src="/driverbee/about/3.svg"
                  alt="about-icon-3"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="text-row">
              <p>
                A smart dashboard that analyzes driver’s behavior data to
                generate accurate and personalized reports, which can be used to
                coach drivers to improve their professional skills and maximize
                incentives.{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
