import React from "react"
import "./appDisplayImage.scss"

export default function AppDisplayImage({ url }) {
  return (
    <div className="key-challanges-img-wrapper">
      <img src={url} alt="driverbee-app-display-img" loading="lazy" />
    </div>
  )
}
